<template>
    <svg
        version="1.1"
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 761.8 386.2"
        xml:space="preserve"
    >
        <g>
            <path
                class="fill-dba"
                d="M31.8,338.7c4,2.1,7.2,5,9.4,8.9s3.3,8.3,3.3,13.4c0,5.1-1.1,9.5-3.3,13.3c-2.2,3.8-5.3,6.7-9.4,8.8 c-4,2.1-8.7,3.1-14,3.1H0v-50.6h17.7C23,335.6,27.7,336.6,31.8,338.7L31.8,338.7z M29.7,373.2c2.9-2.9,4.3-7,4.3-12.3 c0-5.3-1.5-9.4-4.4-12.4c-2.9-3-7-4.5-12.3-4.5h-7.2v33.5h7.2C22.7,377.6,26.8,376.1,29.7,373.2L29.7,373.2z"
            />
            <path
                class="fill-dba"
                d="M76.7,343.8v12.7h17.1v8H76.7v13.4h19.2v8.3H66.5v-50.7h29.4v8.3L76.7,343.8L76.7,343.8z"
            />
            <path
                class="fill-dba"
                d="M155.4,364.5c1.8,2.3,2.8,5,2.8,8c0,2.7-0.7,5.1-2,7.1s-3.2,3.7-5.8,4.8c-2.5,1.2-5.5,1.7-8.9,1.7h-21.8v-50.6 h20.9c3.4,0,6.4,0.6,8.9,1.7c2.5,1.1,4.4,2.7,5.7,4.6s1.9,4.2,1.9,6.8c0,3-0.8,5.4-2.4,7.4c-1.6,2-3.7,3.4-6.3,4.2 C151.2,360.8,153.6,362.2,155.4,364.5L155.4,364.5z M129.8,356.5h9.3c2.4,0,4.3-0.5,5.6-1.6s2-2.6,2-4.7s-0.6-3.6-2-4.7 c-1.3-1.1-3.2-1.7-5.6-1.7h-9.3V356.5z M145.8,376.2c1.4-1.2,2.1-2.8,2.1-4.9c0-2.2-0.7-3.9-2.2-5.1s-3.4-1.9-5.9-1.9h-10v13.6 h10.2C142.5,377.9,144.4,377.4,145.8,376.2L145.8,376.2z"
            />
            <path
                class="fill-dba"
                d="M191,343.8v12.7H208v8H191v13.4h19.2v8.3h-29.4v-50.7h29.4v8.3L191,343.8L191,343.8z"
            />
            <path
                class="fill-dba"
                d="M241.9,384.9c-2.8-1.2-5.1-3-6.7-5.2c-1.6-2.3-2.5-5-2.5-8h10.9c0.1,2.1,0.9,3.7,2.2,4.9 c1.3,1.2,3.2,1.8,5.5,1.8s4.2-0.6,5.6-1.7c1.4-1.1,2-2.6,2-4.5c0-1.5-0.5-2.7-1.4-3.7s-2.1-1.7-3.4-2.3c-1.4-0.6-3.3-1.2-5.7-1.9 c-3.3-1-6-1.9-8-2.9c-2.1-0.9-3.8-2.4-5.3-4.3s-2.2-4.5-2.2-7.7c0-3,0.8-5.6,2.2-7.8c1.5-2.2,3.6-3.9,6.3-5.1 c2.7-1.2,5.8-1.8,9.3-1.8c5.2,0,9.5,1.3,12.7,3.8c3.3,2.5,5.1,6.1,5.4,10.6h-11.2c-0.1-1.7-0.8-3.2-2.2-4.3 c-1.4-1.1-3.2-1.7-5.5-1.7c-2,0-3.6,0.5-4.8,1.5c-1.2,1-1.8,2.5-1.8,4.4c0,1.4,0.4,2.5,1.3,3.4c0.9,0.9,2,1.6,3.3,2.2 s3.2,1.2,5.6,1.9c3.3,1,6,1.9,8,2.9c2.1,1,3.9,2.4,5.4,4.4c1.5,1.9,2.2,4.5,2.2,7.6c0,2.7-0.7,5.2-2.1,7.5 c-1.4,2.3-3.5,4.2-6.2,5.5c-2.7,1.4-5.9,2.1-9.6,2.1C247.9,386.7,244.7,386.1,241.9,384.9L241.9,384.9z"
            />
            <path
                class="fill-dba"
                d="M327.1,335.5v8.2h-13.5v42.5h-10.2v-42.5h-13.5v-8.2H327.1L327.1,335.5z"
            />
            <path
                class="fill-dba"
                d="M359.2,343.8v12.7h17v8h-17v13.4h19.2v8.3H349v-50.7h29.4v8.3L359.2,343.8L359.2,343.8z"
            />
            <path
                class="fill-dba"
                d="M412.3,378.1H429v8h-26.9v-50.6h10.2V378.1L412.3,378.1z"
            />
            <path
                class="fill-dba"
                d="M481,376.5h-20.2l-3.3,9.6h-10.7l18.2-50.7h11.8l18.2,50.7h-10.7L481,376.5L481,376.5z M478.2,368.4l-7.3-21.2 l-7.3,21.2H478.2L478.2,368.4z"
            />
            <path
                class="fill-dba"
                d="M551.4,358.8c-1.3,2.4-3.3,4.3-6,5.7c-2.7,1.5-6.2,2.2-10.3,2.2h-8.5v19.5h-10.2v-50.6h18.7 c3.9,0,7.3,0.7,10,2c2.8,1.4,4.8,3.2,6.2,5.6c1.4,2.4,2.1,5.1,2.1,8C553.4,353.9,552.7,356.5,551.4,358.8L551.4,358.8z M540.9,356.6c1.3-1.3,2-3.1,2-5.4c0-4.9-2.8-7.4-8.3-7.4h-8v14.7h8C537.4,358.6,539.5,357.9,540.9,356.6z"
            />
            <path
                class="fill-dba"
                d="M610.1,358.8c-1.3,2.4-3.3,4.3-6,5.7c-2.7,1.5-6.2,2.2-10.3,2.2h-8.5v19.5H575v-50.6h18.7c3.9,0,7.3,0.7,10,2 c2.8,1.4,4.8,3.2,6.2,5.6c1.4,2.4,2.1,5.1,2.1,8C612,353.9,611.3,356.5,610.1,358.8L610.1,358.8z M599.5,356.6c1.3-1.3,2-3.1,2-5.4 c0-4.9-2.8-7.4-8.3-7.4h-8v14.7h8C596.1,358.6,598.2,357.9,599.5,356.6z"
            />
            <path
                class="fill-dba"
                d="M633.6,385c-1.2-1.1-1.8-2.5-1.8-4.2s0.6-3.1,1.8-4.2c1.2-1.1,2.7-1.7,4.5-1.7s3.3,0.6,4.4,1.7 c1.2,1.1,1.7,2.5,1.7,4.2s-0.6,3.1-1.7,4.2c-1.2,1.1-2.6,1.7-4.4,1.7S634.8,386.1,633.6,385z"
            />
            <path
                class="fill-dba"
                d="M710.4,386.2h-10.2l-23-34.8v34.8h-10.2v-50.7h10.2l23,34.8v-34.8h10.2V386.2L710.4,386.2z"
            />
            <path
                class="fill-dba"
                d="M745.1,378.1h16.7v8h-26.8v-50.6h10.2V378.1L745.1,378.1z"
            />
        </g>
        <path
            class="fill-dba"
            d="M761.2,248.7L722,29.9C718.9,12.6,703.8,0,686.3,0h-77.8C591,0,576,12.6,572.9,29.9L526,291.3H636 c0-0.5,0-0.9,0.1-1.4c1.2-19.5-7-27.8-11.8-30.9c-2.1-1.4-3.5-3.7-3.7-6.3c-14.4-144.9,10.7-199.3,35.2-199.3c6.3,0,8.5,4.3,8.5,9.4 c0,11.6,0,136.5,0,228.4h61.3C748.1,291.3,765.2,270.9,761.2,248.7L761.2,248.7z"
        />
        <path
            class="fill-dba"
            d="M129.5,0H36.2C16.2,0,0,16.2,0,36.2v255.1h129.5c80.4,0,113.3-65.2,113.3-145.7l0,0C242.8,65.2,209.9,0,129.5,0 z M131.3,205.7c-14.6,18.1-18.7,33.8-16.2,85.7H98.7c2.5-51.9-1.6-67.6-16.2-85.7l0,0c-6.8-8.5-11.1-20.1-10.4-32.3L77,97.6 l2.5-42.9c0.1-1.4,1.3-2.5,2.7-2.4c1.3,0.1,2.4,1.2,2.4,2.6l2.9,87.7c0.1,2,1.7,3.6,3.7,3.6c2,0,3.6-1.7,3.6-3.7L95.3,55 c0-1.8,1.8-3.2,3.8-2.5c1,0.4,1.6,1.3,1.6,2.4l3.7,88.8c0.1,1.3,1.2,2.4,2.5,2.3c1.3,0,2.4-1,2.5-2.3l3.7-88.7 c0.1-1.8,1.9-3.2,3.8-2.4c1,0.4,1.6,1.4,1.6,2.4l0.4,87.5c0,2,1.6,3.6,3.6,3.7c2,0,3.7-1.6,3.7-3.6l3-87.8c0.1-1.5,1.4-2.6,2.9-2.5 c1.2,0.1,2.2,1.2,2.3,2.5l2.5,42.8l4.9,75.7C142.4,185.6,138.1,197.2,131.3,205.7L131.3,205.7L131.3,205.7z"
        />
        <path
            class="fill-dba"
            d="M479.3,145.7c19.6-14.8,32.4-38.3,32.4-64.7l0,0c0-44.7-36.2-80.9-80.9-80.9H305.1c-20,0-36.2,16.2-36.2,36.2 v255.1h93.9c3-60.3-1.4-78.2-18.1-98.9l0.1,0c-11.7-13.9-19.3-36.6-19.3-62.2c0-42.4,20.9-76.8,46.6-76.8s46.6,34.4,46.6,76.8 c0,25.6-7.6,48.3-19.3,62.2l0.1,0c-16.7,20.7-21.1,38.5-18.1,98.9H375h55.7c44.7,0,80.9-36.2,80.9-80.9l0,0 C511.6,183.9,498.9,160.4,479.3,145.7L479.3,145.7z"
        />
    </svg>
</template>
