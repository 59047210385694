<template>
    <div
        class="divide-y divide-gray-200 rounded bg-white shadow dark:divide-gray-900 dark:bg-gray-950"
    >
        <slot name="header" />

        <slot />

        <div
            class="flex flex-row *:flex-1 [&>*:first-child]:rounded-bl [&>*:last-child]:rounded-br"
            v-if="$slots.footer"
        >
            <slot name="footer" />
        </div>
    </div>
</template>
